import React from "react"
import { Tabs,Alert,Col,Row,Tab, Container,Modal,Button, Card } from "react-bootstrap"
import Section  from "../components/Section/Section.component"
import SimpleReactLightbox,{ SRLWrapper } from "simple-react-lightbox";

const About = () => {

  const content = "We offer a range of services including warehousing, logistics, assembly, packaging and light manufacturing. Our extensive scope and flexibility means that we can value-add for our customers at every stage in the supply chain process. As a commercial division of HELP, we are also a proud disability friendly workplace, with a 90 percent supported employee ratio.";
 
  const options = {
    
    buttons: {
      showDownloadButton	:false,
      
    },
    
  };
  

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            About Us
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Centered Modal</h4>
          <p>
           
          </p>
          <p>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
            consectetur ac, vestibulum at eros.
          </p>
          <p>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
            consectetur ac, vestibulum at eros.
          </p>
          <p>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
            consectetur ac, vestibulum at eros.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
 
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div>
      <h1>About Us</h1>
      <p style={{    textAlign: "justify"}}> AIDIA industrial Pty ltd is an Australian based company. We specialise in providing technical consultancy, turnkey solutions & consolidated supply for industrial services mainly focussing oil and gas, petrochemical, marine and offshore shipping industry. 
      </p>
      <p style={{textAlign: "justify"}}> 
      We have vast network of technically competent teams to proficiently service our clients on projects we undertake globally. Our unique blend of skills, together with focus on continual review & improvement, allows us to remain at the forefront of what we do best. Our core team has over 95 years of cumulative experience working on various projects at different levels for industrial, oil & gas, marine & offshore industry.</p>
      <p style={{textAlign: "justify"}}> 
      Our main areas of expertise includes technical consultancy & turnkey solutions for impressed current cathodic protection systems and passive cathodic (sacrificial anode) protection systems, fire detection & firefighting systems like clean agent fire suppression system, fixed water sprinkler system, fixed co2 firefighting system, diesel electric power generation & distribution systems, HVAC plants, desalination plants, boilers & feed water systems, feed water condition monitoring systems, pressure vessels & fittings, inert gas system, oil purification & separation system, instrumentation & remote control systems, ships dynamic positioning systems, ship propulsion systems & auxiliaries, pumps & piping systems. </p>
       <br/>     
 
      <Container style={{padding:"10px"}}>

        <Tabs defaultActiveKey="culture" id="uncontrolled-tab-example">
            <Tab eventKey="culture" title="Company Culture">
            <Container>
              <br/>
              <p style={{textAlign: "justify"}}>
              We believe in developing long-term business relations with our clients. We take care of our client’s interests and properties as of ours. We believe our individual treatment of clients gives an edge over our competitors.              </p>
              <p style={{textAlign: "justify"}}> 
              We believe safety is a mindset which must be woven into our everyday operations. We are committed to a safe practise rather a pretend culture of forms and paperwork. we show a duty of care for the wellbeing and safety of our colleagues.              </p>
              <p style={{textAlign: "justify"}}>
              We believe in operational excellence, while keeping things uncomplicated & streamlined. We are casual & welcoming but never relaxed. We appreciate, nurture and celebrate each other’s everyday triumphs no matter how small. We believe laughter is essential for healthy workplace.              </p>
              <p style={{textAlign: "justify"}}>
              We believe everyone has the right to be treated with respect. Each one of us do our best, give our best & expect the best from each other. We believe each one of us is accountable for delivering what we say we will do. We strongly believe we know our business inside out.              </p>
              <p style={{textAlign: "justify"}}>
              We believe the accomplishment of the company is a combined effort of each person. We work together to find the best of solution collectively to each of our challenges. we believe we are stronger as a team.              </p>
            </Container>
        
          </Tab>
      <Tab eventKey="vision" title="Vision & Mission">

      <Container>
        <br/>
          <p style={{textAlign: "justify"}}>
          The company is committed to provide world-class industrial services & consolidated supplies that meet or exceed statutory, safety and environmental requirements as well as, client expectations, and to conduct its operations in a manner which protects human safety, quality of the services provided & the environment.          </p>

        <strong>Company's Vision</strong>
        <p style={{textAlign: "justify"}}>
        To be recognized as among the best international industrial services & consolidated supply house in the petrochemical, oil & gas, offshore & marine industry, with a reputation for outstanding performance, reliability, and safety standards.         </p>

        <strong>Company's Mission</strong>
        <p style={{textAlign: "justify"}}>
        At AIDIA our mission is to travel that extra mile to be our customer's first preference in providing safe, high quality and reliable services. We strive towards being a key partner for our client’s interest, ensuring that our services provide value to their business.        </p>
        <p style={{textAlign: "justify"}}>

        AIDIA Industrial Pty ltd is committed to conduct its operations in a manner which promotes safety protects human life, the environment and property. HSEQ responsibilities extend throughout the organization. Everyone is expected to take a personal, proactive, and constructive role in our drive for flawless, efficient, competitive and customer focused operations and to participate in our continuous improvement efforts to achieve our targets & project timelines.</p>
     
      </Container>
      </Tab>
      <Tab eventKey="why_aidia" title="Why AIDIA" tabClassName="tab-style" >
        <Container>
          <br/>
          <p style={{textAlign: "justify"}}>
          We succeed when our Clients realize accomplishment. We lend an attentive ear to the client requirements, challenges, and cost restraints to draw up our approach. We take pride to say what we do we do Best. We follow Industry best practises and put in place MOC to each task of the project by clearly Identifying various scenarios whereby mitigating the risk Implicated by taking Informed Decisions.          </p>
          <Alert variant="primary">
          <p style={{textAlign:"center", paddingTop: "22px"}}> <strong> “A Well-Educated Mind has more Questions than Answers” Helen Keller</strong></p>
          </Alert>

        <p style={{textAlign: "justify"}}>
        We are resolutely committed to client service following a transaction or project accomplishment. The accessibility of our highly skilled and trained professionals act in response to unseemly situation when needed. Our Philosophy and Values do not warrant us to be spoken among Fly by Night kind of operators. We strongly Mean what our reputation speaks of us.        </p>
  
        </Container>
      </Tab>
      <Tab eventKey="policy" title="Policies" tabClassName="tab-style" >
      <SimpleReactLightbox>
          <SRLWrapper options={options}>

          <Container>
              <Row>
                <Col>
                  <a href="../../AIDIA Policy 1.jpg" data-attribute="SRL">

                    <img src="../../AIDIA Policy 1.jpg" width="220px"/>  
                    </a>
                </Col>
                <Col>
                  <a href="../../AIDIA Policy 2.jpg" data-attribute="SRL">

                    <img src="../../AIDIA Policy 2.jpg" width="220px"/>  
                    </a>
                </Col>
                <Col>
                  <a href="../../AIDIA Policy 3_pg1.jpg" data-attribute="SRL">

                    <img src="../../AIDIA Policy 3_pg1.jpg" width="220px"/>  
                    </a>
                </Col>
                <Col>
                  <a href="../../AIDIA Policy 3_pg2.jpg" data-attribute="SRL">

                    <img src="../../AIDIA Policy 3_pg2.jpg" width="220px"/>  
                    </a>
                 </Col>
              </Row>
              <hr/>
              <Row>
                <Col>
                  <a href="../../AIDIA Safety Rules.jpg" data-attribute="SRL">

                    <img src="../../AIDIA Safety Rules.jpg" width="220px"/>  
                    </a>
                </Col>
              </Row>
          </Container>
          </SRLWrapper>
        </SimpleReactLightbox>
      </Tab>
    </Tabs>

  
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
                  </Container>

    </div>
  )
}

export default About
